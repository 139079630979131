<template>
    <div>
        <el-divider></el-divider>
        <el-input placeholder="请输入序列号" v-model="searchObj.onlyText" @change="getList(1)"><el-button slot="append"
                icon="el-icon-search" @click="getList(1)"></el-button></el-input>
        <vxe-table ref="table1" :data="tableData" border :loading="tableLoading" style="margin-top:10px;">
            <vxe-column field="imei" title="Imei"></vxe-column>
            <vxe-column field="iccid" title="Iccid"></vxe-column>
            <vxe-column field="fver" title="Fver"></vxe-column>
            <vxe-column field="csq" title="Csq"></vxe-column>
            <vxe-column field="lastTime" title="最后通讯时间"></vxe-column>
        </vxe-table>
        <vxe-pager align="left" @page-change="getList" :page-size.sync="searchObj.pageSize"
            :current-page.sync="searchObj.currentPage" :total="searchObj.total"
            :layouts="['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Total']">
        </vxe-pager>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableLoading: false,
            searchObj: {
                onlyText: "",
                currentPage: 1,
                pageSize: 10,
                total: 0,
            }, tableData: [],
        }
    },
    methods: {
        getList() {
            let that = this;
            that.tableLoading = true;
            that.axios.post("YinErDa/Get_OnLine", { onlyText: "" }).then((response) => {
                that.tableLoading = false;
                that.tableData = response.data.data.data;
            })
        }
    },
    mounted() {
        let that = this;
        that.getList();
    }

}
</script>

<style></style>